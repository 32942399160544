import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { com.example.blogmultiplatform.pages.HomePage() }
        ctx.router.register("/about/") { com.example.blogmultiplatform.pages.about.AboutPage() }
        ctx.router.register("/admin/") { com.example.blogmultiplatform.pages.admin.HomePage() }
        ctx.router.register("/admin/create") {
                com.example.blogmultiplatform.pages.admin.CreatePage() }
        ctx.router.register("/admin/login") {
                com.example.blogmultiplatform.pages.admin.LoginScreen() }
        ctx.router.register("/admin/my-posts") {
                com.example.blogmultiplatform.pages.admin.MyPostsPage() }
        ctx.router.register("/admin/sign-up") {
                com.example.blogmultiplatform.pages.admin.SignUpPage() }
        ctx.router.register("/admin/success") {
                com.example.blogmultiplatform.pages.admin.SuccessPage() }
        ctx.router.register("/movie/") { com.example.blogmultiplatform.pages.movie.MovieOfTheWeek()
                }
        ctx.router.register("/posts/post") { com.example.blogmultiplatform.pages.posts.PostPage() }
        ctx.router.register("/search/query") {
                com.example.blogmultiplatform.pages.search.SearchPage() }

    }
    router.setLegacyRouteRedirectStrategy(Router.LegacyRouteRedirectStrategy.ALLOW)
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerComponentStyle(com.example.blogmultiplatform.styles.EditorKeyStyle)
        ctx.theme.registerComponentStyle(com.example.blogmultiplatform.styles.CategoryItemStyle)
        ctx.theme.registerComponentStyle(com.example.blogmultiplatform.styles.LoginInputStyle)
        ctx.theme.registerComponentStyle(com.example.blogmultiplatform.styles.NewsletterInputStyle)
        ctx.theme.registerComponentStyle(com.example.blogmultiplatform.styles.PostPreviewStyle)
        ctx.theme.registerComponentStyle(com.example.blogmultiplatform.styles.MainPostPreviewStyle)
        ctx.theme.registerComponentStyle(com.example.blogmultiplatform.styles.NavigationItemStyle)
        com.example.blogmultiplatform.updateTheme(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "byte-sized adventures."))
    renderComposable(rootElementId = "root") {
        com.example.blogmultiplatform.MyApp {
            router.renderActivePage { renderWithDeferred { it() } }
        }
    }
}
