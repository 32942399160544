package com.example.blogmultiplatform.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.toAttrs
import kotlinx.browser.document
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLScriptElement
import kotlin.random.Random

//@Composable
//fun LoadingIndicator(modifier: Modifier = Modifier) {
//    Box(
//        modifier = modifier
//            .fillMaxSize()
//            .height(100.vh)
//            .padding(topBottom = 50.px),
//        contentAlignment = Alignment.Center
//    ) {
//        Div(
//            attrs = Modifier
//                .classNames("spinner-border", "text-primary")
//                .toAttrs()
//        ) {
//            Span(
//                attrs = Modifier
//                    .classNames("visually-hidden")
//                    .toAttrs()
//            ) { Text("Loading...") }
//        }
//    }
//}


@Composable
fun LoadingIndicator(modifier: Modifier = Modifier) {
    val containerId = remember { "lottie-container-${Random.nextDouble()}" }

    Box(
        modifier = modifier
            .fillMaxSize()
            .height(100.vh)
            .padding(topBottom = 50.px),
        contentAlignment = Alignment.Center
    ) {
        Div(
            attrs = Modifier
                .id(containerId)
                .size(200.px)
                .toAttrs()
        )
    }

    DisposableEffect(Unit) {
        val scriptLottie = document.createElement("script") as HTMLScriptElement
        scriptLottie.src = "https://cdnjs.cloudflare.com/ajax/libs/lottie-web/5.9.6/lottie.min.js"
        document.head?.appendChild(scriptLottie)

        scriptLottie.onload = {
            val container = document.getElementById(containerId) as HTMLElement?
            container?.let {
                js("lottie.loadAnimation({container: it, renderer: 'svg', loop: true, autoplay: true, " +
                        "path: '" +
//                        "https://assets2.lottiefiles.com/packages/lf20_usmfx6bp.json" +
                        "https://lottie.host/4513932f-c1d9-41e9-b2b7-abab4de87905/jSCnBJJgwS.json" +
                        "'})")
            }
        }

        onDispose {
//            try {
//                document.head?.removeChild(scriptLottie)
//            }catch (e: Exception) {
//                console.log("Error removing script: $e")
//            }
        }
    }
}