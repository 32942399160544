package com.example.blogmultiplatform.models

sealed class ControlStyle(val style: String) {
    data class Bold(val selectedText: String?) : ControlStyle(
        style = "<strong>$selectedText</strong>"
    )

    data class Italic(val selectedText: String?) : ControlStyle(
        style = "<em>$selectedText</em>"
    )

    data class Link(
        val selectedText: String?,
        val href: String,
        val title: String
    ) : ControlStyle(
        style = "<a href=\"$href\" title=\"$title\">$selectedText</a>"
    )

    data class Title(val selectedText: String?) : ControlStyle(
        style = "<h1><strong>$selectedText</strong></h1>"
    )

    data class Subtitle(val selectedText: String?) : ControlStyle(
        style = "<h3>$selectedText</h3>"
    )

    data class Quote(val selectedText: String?) : ControlStyle(
        style = "<div style=\"background-color:#FAFAFA;padding:12px;border-radius:6px;\"><em>❞ $selectedText</em></div>"
    )

//    data class Code0(val selectedText: String?) : ControlStyle(
//        style = """<div style="background-color:#0d1117;padding:12px;border-radius:6px;position:relative;"><pre><code class="language-kotlin" id="my-code">
//${selectedText?.trimIndent()}
//</code></pre><button onclick="copyToClipboard()" style="position:absolute;top:12px;right:12px;background:none;border:none;color:#fff;cursor:pointer;"><i class="fas fa-copy"></i></button></div>
//        """.trimIndent()
//    )

    data class Code(val selectedText: String?) : ControlStyle(
        style = """<div class="snippet-container">
        <div class="snippet-header">
            <div class="circle red"></div>
            <div class="circle yellow"></div>
            <div class="circle green"></div>
            <div class="filename">Demo.kt</div>
        </div>
        <div style="background-color:#0d1117;padding:12px;border-radius:6px;position:relative;"><pre><code class="language-kotlin" id="my-code">
${selectedText?.trimIndent()}
</code></pre><button class="copy-button" onclick="copyToClipboard()"><i class="fas fa-copy"></i></button></div></div>

        """.trimIndent()
    )
//</code></pre><button onclick="copyToClipboard()" style="position:absolute;top:12px;right:12px;background:none;border:none;color:#fff;cursor:pointer;"><i class="fas fa-copy"></i></button></div>


    data class Poem(val selectedText: String?) : ControlStyle(
        style = """<div class="poem-container">
        <div class="poem-header">
            <div class="title">The Road Not Taken</div>
            <div class="author">by Robert Frost</div>
        </div>
        <div class="poem-body">
<p>${selectedText?.trimIndent()}</p>

</div>
        """.trimIndent()
    )

    // TODO: justify text overlaps with the poem block


    data class Video(val movieUrl: String?) : ControlStyle(
        style = """<div class="iframe-container"><iframe src="$movieUrl" frameborder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>  
        """.trimIndent()
    )

    data class Image(
        val selectedText: String?,
        val imageUrl: String,
        val alt: String
    ) : ControlStyle(
        style = "<img src=\"$imageUrl\" alt=\"$alt\" style=\"max-width: 100%\">$selectedText</img>"
    )

    data class Break(val selectedText: String?) : ControlStyle(
        style = "$selectedText<br>"
    )
}




