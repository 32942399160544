package com.example.blogmultiplatform.pages.posts

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.example.blogmultiplatform.components.CategoryNavigationItems
import com.example.blogmultiplatform.components.ErrorView
import com.example.blogmultiplatform.components.LoadingIndicator
import com.example.blogmultiplatform.components.OverflowSidePanel
import com.example.blogmultiplatform.models.ApiResponse
import com.example.blogmultiplatform.models.Constants.POST_ID_PARAM
import com.example.blogmultiplatform.models.Post
import com.example.blogmultiplatform.navigation.Screen
import com.example.shared.JsTheme
import com.example.blogmultiplatform.sections.FooterSection
import com.example.blogmultiplatform.sections.HeaderSection
import com.example.blogmultiplatform.util.Constants.FONT_FAMILY
import com.example.blogmultiplatform.util.CustomHTMLAttributes
import com.example.blogmultiplatform.util.Id
import com.example.blogmultiplatform.util.Res
import com.example.blogmultiplatform.util.deleteSelectedPosts
import com.example.blogmultiplatform.util.fetchSelectedPost
import com.example.blogmultiplatform.util.isAuthor
import com.example.blogmultiplatform.util.parseDateString
import com.example.shared.Category
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextOverflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textOverflow
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import com.example.shared.Constants.SHOW_SECTIONS_PARAM
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.objectFit
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.jetbrains.compose.web.css.times
import org.w3c.files.File


@Page(routeOverride = "post")
@Composable
fun PostPage() {
    val scope = rememberCoroutineScope()
    val context = rememberPageContext()
    val breakpoint = rememberBreakpoint()
    var overflowOpened by remember { mutableStateOf(false) }
    var showSections by remember { mutableStateOf(true) }
    var apiResponse by remember { mutableStateOf<ApiResponse>(ApiResponse.Idle) }
    val hasPostIdParam = remember(key1 = context.route) {
        context.route.params.containsKey(POST_ID_PARAM)
    }

    LaunchedEffect(key1 = context.route) {
        showSections = if (context.route.params.containsKey(SHOW_SECTIONS_PARAM)) {
            context.route.params.getValue(SHOW_SECTIONS_PARAM).toBoolean()
        } else true
        if (hasPostIdParam) {
            val postId = context.route.params.getValue(POST_ID_PARAM)
            apiResponse = fetchSelectedPost(id = postId)
        }

        val styleElement = document.createElement("style")
        styleElement.textContent = CustomHTMLAttributes.CUSTOM_STYLE.joinToString { it }
        document.head?.appendChild(styleElement)

        val scriptElement = document.createElement("script")
        scriptElement.textContent = CustomHTMLAttributes.CUSTOM_SCRIPT.joinToString { it }
        document.body?.appendChild(scriptElement)


        val postScript = (apiResponse as ApiResponse.Success).data.script
        if (postScript != null) {
            val postScriptElement = document.createElement("script")
            postScriptElement.textContent = postScript
            document.body?.appendChild(postScriptElement)
        }


//        onDispose {
//            // Remove the script when the component is disposed
//            document.body?.removeChild(scriptElement)
//        }
    }

    Column(
        modifier = Modifier.fillMaxSize(),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        if (overflowOpened) {
            OverflowSidePanel(
                onMenuClose = { overflowOpened = false },
                content = { CategoryNavigationItems(vertical = true) }
            )
        }
        if(showSections) {
            HeaderSection(
                breakpoint = breakpoint,
                logo = Res.Image.logo,
                onMenuOpen = { overflowOpened = true }
            )
        }
        when (apiResponse) {
            is ApiResponse.Success -> {
                PostContent(
                    post = (apiResponse as ApiResponse.Success).data,
                    breakpoint = breakpoint,
                )
                scope.launch {
                    delay(50)
                    try {
                        js("hljs.highlightAll()") as Unit
                    } catch (e: Exception) {
                        println(e.message)
                    }
                }
            }

            is ApiResponse.Idle -> {
                LoadingIndicator()
            }

            is ApiResponse.Error -> {
                ErrorView(message = (apiResponse as ApiResponse.Error).message)
            }
        }
        if(showSections) {
            FooterSection(breakpoint = breakpoint)
        }
    }
}


@Composable
fun PostContent(
    post: Post,
    breakpoint: Breakpoint,
    ) {

    val scope = rememberCoroutineScope()
    val context = rememberPageContext()


    LaunchedEffect(post) {
        (document.getElementById(Id.postContent) as HTMLDivElement).innerHTML = post.content
        (document.getElementById(Id.postSubtitle) as HTMLDivElement).innerHTML = post.subtitle

    }
    Column(
        modifier = Modifier
            .margin(top = 50.px, bottom = 200.px)
            .padding(leftRight = 24.px)
            .fillMaxWidth()
            .maxWidth(800.px),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        if (isAuthor(post.author)) {
            Row {
                // edit
                Link(
                    path = "/admin/create?postId=${post._id}",
                    text = "Edit",
                    modifier = Modifier
                        .fillMaxWidth()
                        .color(JsTheme.Primary.rgb)
                        .fontFamily(FONT_FAMILY)
                        .fontSize(20.px)
                        .fontWeight(FontWeight.Bold)
                        .margin(bottom = 20.px)

                )
                SpanText("    |    ", modifier = Modifier.fillMaxWidth())
                // delete

                Link(
                    text = "Delete",
                    modifier = Modifier
                        .fillMaxWidth()
                        .color(JsTheme.Red.rgb)
                        .fontFamily(FONT_FAMILY)
                        .fontSize(20.px)
                        .fontWeight(FontWeight.Bold)
                        .margin(bottom = 20.px)
                        .onClick {
                            if (window.confirm("Are you sure you want to delete this post?")) {
                                scope.launch {
                                    if (deleteSelectedPosts(listOf(post._id))){
                                        window.alert("Post deleted successfully.")
                                        context.router.navigateTo(Screen.HomePage.route)
                                    }

                                    else
                                        window.alert("Something went wrong. Please try again.")
                                }

                            }
                        },
                    path = ""
                )
            }
        }
        SpanText(
            modifier = Modifier
                .fillMaxWidth()
                .color(JsTheme.HalfBlack.rgb)
                .fontFamily(FONT_FAMILY)
                .fontSize(14.px),
            text = post.date.toLong().parseDateString()
        )
        SpanText(
            modifier = Modifier
                .fillMaxWidth()
                .margin(bottom = 20.px)
                .color(Colors.Black)
                .fontFamily(FONT_FAMILY)
                .fontSize(40.px)
                .fontWeight(FontWeight.Bold)
//                .overflow(Overflow.Hidden)
                .overflow(Overflow.Visible)
//                .textOverflow(TextOverflow.Ellipsis)
                .styleModifier {
                    property("display", "block") // Change from "-webkit-box" to "block"
//                    property("display", "-webkit-box")
                    property("-webkit-line-clamp", "2")
                    property("line-clamp", "2")
                    property("-webkit-box-orient", "vertical")
                },
            text = post.title
        )
        Div(
            attrs = Modifier
                .id(Id.postSubtitle)
                .fillMaxWidth()
                .margin(bottom = 20.px)
                .color(JsTheme.HalfBlack.rgb)
                .fontFamily(FONT_FAMILY)
                .fontSize(20.px)
                .fontWeight(FontWeight.Bold)
                .toAttrs()
        )

        // TODO: REVERT THE IMAGE
        if (post.category == Category.MovieOfTheWeek){
            Image(
                modifier = Modifier
                    .margin(bottom = 40.px)
                    .fillMaxWidth()
                    .objectFit(ObjectFit.Cover)
                    ,
                src = post.thumbnail,
            )
        }

        Div(
            attrs = Modifier
                .id(Id.postContent)
                .fontFamily(FONT_FAMILY)
                .fillMaxWidth()
                .toAttrs()
        )

    }
}