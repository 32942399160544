package com.example.blogmultiplatform.util

object Constants {
    const val FONT_FAMILY = "Courier New"//"Roboto"
    const val SIDE_PANEL_WIDTH = 250
    const val PAGE_WIDTH = 1920
    const val HEADER_HEIGHT = 100
    const val COLLAPSED_PANEL_HEIGHT = 100
    const val HUMOR_API_URL = "https://api.humorapi.com/jokes/random?api-key=f0cac1365ead42e58b2ee94684b45b56"
    const val DEFAULT_THUMBNAIL_URL = "https://sametb.com/playing-the-triangle.png"
}

object Res {
    object Image {
        const val logo = "/logo.svg"
        const val logoHome = "logo.svg"
        const val laugh = "/laugh.png"
        const val movie = "/video.svg"
    }
    object Icon {
        const val bold = "/bold.svg"
        const val italic = "/italic.svg"
        const val link = "/link.svg"
        const val title = "/title.svg"
        const val subtitle = "/subtitle.svg"
        const val quote = "/quote.svg"
        const val code = "/code.svg"
        const val image = "/image.svg"
        const val video = "/video.svg"
        const val poem = "/poem.svg"
        const val checkmark = "/checkmark.svg"
    }
    object PathIcon {
        const val home = "M3 12L5 10M5 10L12 3L19 10M5 10V20C5 20.2652 5.10536 20.5196 5.29289 20.7071C5.48043 20.8946 5.73478 21 6 21H9M19 10L21 12M19 10V20C19 20.2652 18.8946 20.5196 18.7071 20.7071C18.5196 20.8946 18.2652 21 18 21H15M9 21C9.26522 21 9.51957 20.8946 9.70711 20.7071C9.89464 20.5196 10 20.2652 10 20V16C10 15.7348 10.1054 15.4804 10.2929 15.2929C10.4804 15.1054 10.7348 15 11 15H13C13.2652 15 13.5196 15.1054 13.7071 15.2929C13.8946 15.4804 14 15.7348 14 16V20C14 20.2652 14.1054 20.5196 14.2929 20.7071C14.4804 20.8946 14.7348 21 15 21M9 21H15"
        const val create = "M12 9.52148V12.5215M12 12.5215V15.5215M12 12.5215H15M12 12.5215H9M21 12.5215C21 13.7034 20.7672 14.8737 20.3149 15.9656C19.8626 17.0576 19.1997 18.0497 18.364 18.8854C17.5282 19.7212 16.5361 20.3841 15.4442 20.8364C14.3522 21.2887 13.1819 21.5215 12 21.5215C10.8181 21.5215 9.64778 21.2887 8.55585 20.8364C7.46392 20.3841 6.47177 19.7212 5.63604 18.8854C4.80031 18.0497 4.13738 17.0576 3.68508 15.9656C3.23279 14.8737 3 13.7034 3 12.5215C3 10.1345 3.94821 7.84535 5.63604 6.15752C7.32387 4.4697 9.61305 3.52148 12 3.52148C14.3869 3.52148 16.6761 4.4697 18.364 6.15752C20.0518 7.84535 21 10.1345 21 12.5215Z"
        const val posts = "M9 5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V7C19 6.46957 18.7893 5.96086 18.4142 5.58579C18.0391 5.21071 17.5304 5 17 5H15M9 5C9 5.53043 9.21071 6.03914 9.58579 6.41421C9.96086 6.78929 10.4696 7 11 7H13C13.5304 7 14.0391 6.78929 14.4142 6.41421C14.7893 6.03914 15 5.53043 15 5M9 5C9 4.46957 9.21071 3.96086 9.58579 3.58579C9.96086 3.21071 10.4696 3 11 3H13C13.5304 3 14.0391 3.21071 14.4142 3.58579C14.7893 3.96086 15 4.46957 15 5M12 12H15M12 16H15M9 12H9.01M9 16H9.01"
        const val logout = "M11 16.5215L7 12.5215M7 12.5215L11 8.52148M7 12.5215H21M16 16.5215V17.5215C16 18.3171 15.6839 19.0802 15.1213 19.6428C14.5587 20.2054 13.7956 20.5215 13 20.5215H6C5.20435 20.5215 4.44129 20.2054 3.87868 19.6428C3.31607 19.0802 3 18.3171 3 17.5215V7.52148C3 6.72583 3.31607 5.96277 3.87868 5.40016C4.44129 4.83755 5.20435 4.52148 6 4.52148H13C13.7956 4.52148 14.5587 4.83755 15.1213 5.40016C15.6839 5.96277 16 6.72583 16 7.52148V8.52148"
    }
}

object Id {
    const val usernameInput = "usernameInput"
    const val passwordInput = "passwordInput"
    const val svgParent = "svgParent"
    const val vectorIcon = "vectorIcon"
    const val navigationText = "navigationText"
    const val editor = "editor"
    const val editorPreview = "editorPreview"
    const val titleInput = "titleInput"
    const val subtitleInput = "subtitleInput"
    const val thumbnailInput = "thumbnailInput"
    const val linkHrefInput = "linkHrefInput"
    const val linkTitleInput = "linkTitleInput"
    const val adminSearchBar = "adminSearchBar"
    const val emailInput = "emailInput"
    const val postContent = "postContent"
    const val postSubtitle = "postSubtitle"
    const val scriptString = "scriptString"
}


object StringPlaceholders {
    const val BLANK = ""
}


val titleExceptions = mapOf(
    "MovieOfTheWeek" to "Movie of the Week",
    "Art" to "Art & Literature",
)


object CustomHTMLAttributes {
    val CUSTOM_STYLE = arrayOf(
        """
    .iframe-container {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%; /* 16:9 aspect ratio */
      /* for a 4:3 aspect ratio, use padding-bottom: 75%; */
      /* for a 21:9 aspect ratio, use padding-bottom: 42.86%; */
    }

    .iframe-container iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
    
/*
    #postContent {
       text-align: justify;
       text-justify: inter-word;
    }
*/
    
    
    .snippet-container {
        background-color: #0d1117;
        padding: 12px;
        border-radius: 6px;
        position: relative;
        <!--font-family: 'Courier New', Courier, monospace;-->
    }

    .snippet-header {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
    }

    .snippet-header .circle {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 8px;
    }

    .snippet-header .circle.red {
        background-color: #ff5f56;
    }

    .snippet-header .circle.yellow {
        background-color: #ffbd2e;
    }

    .snippet-header .circle.green {
        background-color: #27c93f;
    }

    .snippet-header .filename {
        color: #fff;
        font-size: 14px;
        margin-left: 16px;
    }

    .copy-button {
        position: absolute;
        top: 2px;
        right: 12px;
        background: none;
        border: none;
        color: #fff;
        cursor: pointer;
    }
    pre {
        margin: 0;
    }
    
            /* Poem styles */
            .poem-container {
            background-color: #fffdf6;
            padding: 20px 40px;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border: 1px solid #e0d9b9;
            width: 100%;
            max-width: 900px;
            box-sizing: border-box;
                
            }
            
            
            
            .poem-header {
                display: flex;
                align-items: center;
                border-bottom: 2px solid #e0d9b9;
                padding-bottom: 10px;
                margin-bottom: 20px;
            }

            .poem-header .title {
                font-size: 24px;
                color: #333;
                flex: 1;
            }

            .poem-header .author {
                font-size: 18px;
                color: #777;
            }

            .poem-body {
                color: #333;
                line-height: 1.6;
            }
                        
            
            /* Hide scrollbar for Chrome, Safari and Opera */
            
            /* For WebKit browsers (e.g., Chrome, Safari) */
            body::-webkit-scrollbar {
                display: none;
            }
            
            /* For Firefox */
            body {
                scrollbar-width: none; /* hides scrollbar but allows scrolling */
            }
            
            /* Ensure the body and html elements occupy full height */
            html, body {
                height: 100%;
                overflow: auto;
            }
            
    
        """.trimIndent()
    ,
    )

//                    body {
//                        background-color: #f5f5dc;
//                        font-family: 'Times New Roman', Times, serif;
//                        display: flex;
//                        justify-content: center;
//                        align-items: center;
//                        height: 100vh;
//                        margin: 0;
//                    }


    val CUSTOM_SCRIPT  = arrayOf(
        """
            function copyToClipboard() {
                const codeElement = document.getElementById('my-code');
                const code = codeElement.textContent;
                
                const textarea = document.createElement('textarea');
                textarea.value = code;
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand('copy');
                document.body.removeChild(textarea);
               
            }
            
            
        
        
        
    
    
    
           
            
        """.trimIndent()
        ,
    )

}

/*
function generateLink() {
    const message = encodeURIComponent(document.getElementById('message').value);
    const size = document.getElementById('size').value;
    const bg = encodeURIComponent(document.getElementById('bg').value);
    const title = encodeURIComponent(document.getElementById('title').value);

    const link = `mysite.co/slider?message=${message}&size=${size}&bg=${bg}&title=\${title}`;
    document.getElementById('sliderLink').innerHTML = `<a href="${link}" target="_blank">${link}</a>`;
}

*/

